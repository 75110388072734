import React from "react"

const platforms = [
  {
    name: "Twitter",
    url(url, title) {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(title)}`
    },
  },
  {
    name: "Facebook",
    url(url) {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`
    },
  },
  {
    name: "LinkedIn",
    url(url, title) {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url
      )}&title=${encodeURIComponent(title)}
      `
    },
  },
  {
    name: "Email",
    url(url, title) {
      return `mailto:?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(url)}`
    },
  },
]

const Share = ({ url, title }) => {
  return (
    <div className="text-sm mt-12">
      <p className="font-mono font-medium text-sm mb-1">Share:</p>
      <ul>
        {platforms.map(platform => (
          <li key={platform.name} className="inline mr-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
              href={platform.url(url, title)}
            >
              {platform.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Share
