import React from "react"
// import { Link } from "gatsby"

import Share from "./share"
// import Tags from "./tags"

const ArticleFooter = ({ url, title, tags, previous, next, author }) => (
  <footer>
    <Share url={url} title={title} />
    {/* <Tags tags={tags} /> */}
    <div className="pt-8 border-t mt-8 border-lightSteel flex flex-wrap">
      <div className="w-full sm:w-1/4">
        <img
          alt=""
          className="mb-4 sm:mb-0"
          src={author.avatar.childImageSharp.original.src}
        />
      </div>
      <div className="sm:pl-8 w-full sm:w-3/4 flex flex-col">
        <h4 className="font-mono font-medium text-sm mb-2 sm:mb-1">
          {author.name}
        </h4>
        <p className="text-sm">{author.bio}</p>
        <div className="flex-grow" />
        <a
          className="mt-2 font-mono text-sm underline"
          href={author.social.url}
        >
          {author.social.text}
        </a>
      </div>
    </div>
    {/* <nav className="mt-20 font-mono text-xs">
      <ul className="flex justify-between">
        <li className="w-1/2">
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li className="w-1/2">
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav> */}
  </footer>
)

export default ArticleFooter
