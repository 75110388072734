import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { capitalize } from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Category from "../components/category"
import ArticleFooter from "../components/article-footer"

function BlogPostTemplate({ data, pageContext, location }) {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const BannerCaption = () => {
    return (
      <figcaption className="px-2 text-xs font-mono max-w-6xl mx-auto text-right mt-2 italic text-steel">
        Photo By{" "}
        <a href={post.frontmatter.bannerCreditUrl}>
          {post.frontmatter.bannerCreditName}
        </a>
      </figcaption>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <figure>
            {post.frontmatter.bannerType == "full" ? (
              <>
                <div className="xl:mt-12 max-w-6xl mx-auto max-h-500px overflow-hidden">
                  <Img
                    imgStyle={{
                      objectFit: "cover",
                      width: "auto",
                      height: "auto",
                      right: "0",
                      margin: "auto",
                    }}
                    fluid={
                      post.frontmatter.banner &&
                      post.frontmatter.banner.childImageSharp.fluid
                    }
                  />
                </div>
                {post.frontmatter.bannerCreditUrl &&
                  post.frontmatter.bannerCreditName && (
                    <div className="flex flex-center">
                      <BannerCaption />
                    </div>
                  )}
              </>
            ) : (
              <>
                <Img
                  className="xl:mt-12 max-w-6xl mx-auto max-h-500px"
                  fluid={
                    post.frontmatter.banner &&
                    post.frontmatter.banner.childImageSharp.fluid
                  }
                />
                {post.frontmatter.bannerCreditUrl &&
                  post.frontmatter.bannerCreditName && <BannerCaption />}
              </>
            )}
          </figure>
          <div className="lg:max-w-3xl md:max-w-xl max-w-lg px-4 md:px-0 mx-auto">
            <Category
              slug={post.frontmatter.category}
              className="mt-8 lg:mt-12 mb-10"
            >
              {capitalize(post.frontmatter.category)}
            </Category>
            <h1 className="mb-4 text-3xl lg:text-5xl font-medium leading-xtight">
              {post.frontmatter.title}
            </h1>
            <p className="font-mono text-lg lg:text-2xl text-red my-8 lg:my-12 leading-snug">
              {post.excerpt}
            </p>
          </div>
        </header>
        <div className="lg:max-w-2xl md:max-w-lg max-w-lg px-4 md:px-0 mx-auto">
          <section
            className="content text-base lg:text-xl leading-normal lg:leading-relaxed text-black70"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <ArticleFooter
            tags={[
              { name: "Elixir", url: "#" },
              { name: "Microservices", url: "#" },
              { name: "Architecture", url: "#" },
            ]}
            next={next}
            previous={previous}
            author={post.frontmatter.author}
            url={`${data.site.siteMetadata.siteUrl}${post.fields.slug}`}
            title={post.frontmatter.title}
          />
        </div>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        category
        date(formatString: "MMMM DD, YYYY")
        author {
          name
          bio
          social {
            text
            url
          }
          avatar {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        banner {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerType
        bannerCreditName
        bannerCreditUrl
      }
    }
  }
`
